'use client'
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { PlaceholdersAndVanishInput } from "@/components/ui/placeholders-and-vanish-input";
import { ImageGenActions, useGeneratedImages } from "@/contexts/GeneratedImagesProvider";
import { useNonUrlContext } from "@/contexts/NonUrlContext";
import { useSessionStorageContext } from "@/contexts/SessionStorageContextProvider";
import { ColorType } from "@/utils/APIRouteTypes";
import { AnimatePresence, motion } from "framer-motion";
import { XCircleIcon } from "lucide-react";
import React, {
  useEffect,
  useState,
} from "react";
import LetsTalk from "../detailComponents/letsTalk";
import FeedbackModal from "../others/feedbackModal";
import ImageGenerationCarousel from "./imageGenerationCarousel";
import ImageList from "./ImageList";

type ImageGenerateItemProps = {
  onReset?: () => void
  selectedImages: string[];
  handlePromptImageClick: (src: string) => void;
  productSlug?: string
  colorType?: ColorType
};


const placeholders = [
  "skull, lava",
  "a royal lion",
  "wolf, howling, full moon",
  "ironman, fire in the background",
  "polar bear, drinking a cocktail, in a desert",
  "astronaut in an alien planet",
  "hulk, smashing a car",
  "cute hippo with his son",
  "elephant, chasing away a car, snowy mountains",
  "spider man, swinging through buildings",
  "a valley at foothills of himalayas",
  "bear holding a gun",
  "snowy mountain",
  "rabbit, riding a rocket, in space",
  "a dolphin, flying through the clouds, smiling",
  "cat, scuba diving in deep sea",
  "husky, drinking beer",
  "majestic horse, running at a beach",
  "bird view of new york skyline",
  "fighter jet crashing in a forest",
  "bulldog, wearing sunglasses, smiling",
  "squirrel, surfing in sea",
  "cute pig rolling in the mud",
];


export const ImageGenerateItem = (props: ImageGenerateItemProps) => {
  const [inputValue, setInputValue] = React.useState("");
  const [sessionStorage, setSessionStorage] = useSessionStorageContext()
  const [generatedImages, setGeneratedImages, genImage] = useGeneratedImages()
  const [showResetModal, setShowResetModal] = useState(false);
  const { sampleImages } = useNonUrlContext()


  const handlePromptSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSessionStorage(prev => ({ ...prev, prompt: inputValue, colorMode: props.colorType }))
    genImage(inputValue, props.productSlug, props.colorType)
  };

  const handleConfirmReset = () => {
    setShowResetModal(false);
    handleReset();
  };

  const handleReset = () => {
    setGeneratedImages({ type: ImageGenActions.CLEAR });
    if (props.onReset)
      props.onReset()
  };


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handlePromptImageClick = (src: string) => {
    props.handlePromptImageClick(src)
  }

  useEffect(() => {
    if (sessionStorage.prompt) {
      setInputValue(sessionStorage.prompt)
    }
  }, [sessionStorage.prompt])

  return (
    <div className="my-3 w-full px-0 md:px-2">
      <FeedbackModal />
      <div className="flex flex-row items-center justify-center">
        <div className="grid w-full grid-flow-col items-center justify-stretch p-0">
          <PlaceholdersAndVanishInput
            placeholders={placeholders}
            showBorder={generatedImages.length === 0}
            onSubmit={handlePromptSubmit}
            onChange={handleChange}
            inputDisabled={sessionStorage.isGeneratingImage}
          />
        </div>

        <Dialog open={showResetModal} onOpenChange={setShowResetModal}>
          <DialogContent className="z-[100]">
            <DialogHeader >
              <DialogTitle>Clear Results?</DialogTitle>
            </DialogHeader>
            <div className="flex min-h-20 items-center">
              <p>Are you sure you want to clear all the results?</p>
            </div>
            <DialogFooter className="flex flex-row gap-2 justify-end pb-2">
              <Button variant="outline" onClick={() => setShowResetModal(false)} id="reset-cancelled">
                Cancel
              </Button>
              <Button variant="default" onClick={handleConfirmReset} id="reset-confirmed">
                Yes, Clear
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
      <AnimatePresence>
        {sessionStorage.isGeneratingImage && (
          <motion.div
            initial={{ opacity: 0, filter: "blur(8px)", height: "0px" }}
            animate={{ opacity: 1, filter: "blur(0px)", height: 'auto' }}
            transition={{
              delay: 0.1,
              duration: 0.3,
            }}
            className="transition-all"
            exit={{ opacity: 0, filter: "blur(8px)", height: "0px" }}
          >
            <div className="mt-3">Generating: {inputValue}</div>
            <progress className="progress progress-primary w-full transition-all" />
            <LetsTalk />
            <ImageGenerationCarousel />
          </motion.div>
        )}
      </AnimatePresence>
      {generatedImages.length == 0 && <ImageList rainbow images={sampleImages.map(x => ({ src: x.image, bgMode: ColorType.LIGHT }))} selectedImages={props.selectedImages} onImageClick={handlePromptImageClick} />}
      {generatedImages.length > 0 && (
        <div className="my-2">
          <div className="flex mt-3  items-center justify-between">

            <div>
              <h2 className="text-sm md:text-xl font-semibold dark:text-white">
                Your Images
              </h2>
              <p className="text-xs sm:text-sm text-slate-500">
                Select images to add to your design
              </p>
            </div>
            <div
              className="flex items-center justify-center rounded-full border-red-600 sm:p-1 text-red-600 transition-all hover:bg-red-600 hover:text-white xs:border"
              data-tooltip-content={'Clear Results'}
              data-tooltip-id="tooltip"
            >
              <button
                className="flex items-center justify-center text-sm"
                onClick={() => setShowResetModal(true)}
                disabled={sessionStorage.isGeneratingImage}
                id="reset-button"
              >
                <XCircleIcon className="size-6" />
                <span className="hidden pe-2 ps-1 text-sm xs:block">Reset</span>
              </button>
            </div>

          </div>

          <ImageList images={generatedImages.map(x => ({ src: x.src, bgMode: x.color }))} selectedImages={props.selectedImages} onImageClick={handlePromptImageClick} />
        </div>
      )}

    </div>
  );
};


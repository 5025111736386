'use client'

import { cn } from '@/lib/utils';
import { AnimatePresence, motion } from "framer-motion";
import Image, { ImageProps } from 'next/image';
import React, { useEffect, useState } from 'react';

type ImageWithLoaderProps = {
    spinnerSize: 'sm' | 'md' | 'lg' | 'xl',
    handleLoad?: () => void
    fullImageRequired?: boolean
    blackBG?: boolean
} & ImageProps

export default function ImageWithLoader(props: ImageWithLoaderProps) {

    const [loading, setLoading] = useState(true)

    const handleLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
        setLoading(false)
        if (props.handleLoad)
            props.handleLoad()


        if (props.fullImageRequired) {
            if (e.currentTarget.naturalHeight >= e.currentTarget.naturalWidth) {
                e.currentTarget.style.height = '100%'
                e.currentTarget.style.width = 'auto'
            }
            else {
                e.currentTarget.style.width = '100%'
                e.currentTarget.style.height = 'auto'
            }
        }

        e.currentTarget.style.opacity = '1'
    }

    useEffect(() => {
        setLoading(true)
    }, [props.src])

    return (
        <div className={cn("relative w-full self-center aspect-square items-center justify-center flex overflow-hidden", {
            'bg-black': props.blackBG,
            'bg-white': !props.blackBG
        })} key={props.key}>
            <AnimatePresence>
                {loading && <motion.div
                    initial={{ opacity: 0, y: 10, filter: "blur(8px)" }}
                    animate={{ opacity: 1, y: 0, filter: "blur(0px)" }}
                    transition={{
                        delay: 0.1,
                        duration: 0.2,
                    }}
                    className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-30 z-20 flex items-center justify-center"
                >
                    <span className="loading loading-spinner loading-lg bg-indigo-800" />
                </motion.div>}
            </AnimatePresence>
            <Image {...props} onLoad={handleLoad} style={{ opacity: 0 }} />
        </div>
    )
}

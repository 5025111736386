'use client'
import { ImageGenActions, useGeneratedImages } from "@/contexts/GeneratedImagesProvider";
import { cn } from "@/utils/cn";
import { motion } from 'framer-motion';
import { CiCircleCheck } from 'react-icons/ci';
import ImageWithLoader from "../others/ImageWithLoader";

export const PromptImage = (props: {
    src: string;
    selected: boolean;
    onClick?: (src: string) => void;
    rainbow?: boolean
    noClickShow?: boolean
    checkMessage?: string
    isDark?: boolean
}) => {
    const [generatedImages, setGeneratedImages, _] = useGeneratedImages()
    const imgIndex = generatedImages.findIndex((item) => item.src === props.src);

    const handleClick = (e: React.MouseEvent<HTMLImageElement>) => {
        if (props.onClick)
            props.onClick(props.src);
        setGeneratedImages({ type: ImageGenActions.MARK_CLICKED, payload: { src: props.src } });
    };

    return (
        <motion.div className={cn('w-full aspect-square bg-gradient-to-tr from-ffmain-900 via-ffmain-700 to-ffmain-900 p-[1px] rounded-md hover:scale-[1.01] group transition-all ', {
            'rainbow-input p-0.5': props.rainbow
        })}
            initial={{ opacity: 0, filter: "blur(8px)" }}
            animate={{ opacity: 1, filter: "blur(0px)" }}
            transition={{
                delay: 0.1,
                duration: 0.2,
            }}
        >
            <div className={cn(`h-full relative rounded-md transition-all  overflow-hidden shadow-md`, {
                "group-hover:bg-slate-100 bg-white": !props.isDark,
                "group-hover:bg-slate-800 bg-black": props.isDark
            })}>
                {props.selected && (
                    <div className="pointer-events-none absolute flex flex-col size-full items-center justify-center bg-black bg-opacity-30 text-green-200 z-20 ">
                        <CiCircleCheck className="size-10" />
                        {props.checkMessage && <div className="text-xs text-center">{props.checkMessage}</div>}
                    </div>
                )}

                {!props.noClickShow && !generatedImages[imgIndex]?.isClicked && (
                    <div className="absolute right-2 top-2 size-2 rounded-full   bg-gradient-to-b from-ffmain-600 to-yellow-400 z-20"></div>
                )}

                <div className='size-full relative'>
                    <ImageWithLoader
                        src={props.src}
                        spinnerSize='md'
                        alt={`Generated image`}
                        sizes='(max-width: 530px) 24vw, (max-width: 768px) 19vw, (max-width: 1024px) 10vw, 8vw'
                        fill
                        className={`z-10 cursor-pointer object-cover transition generated-image`}
                        data-track="Generated Image"
                        onClick={handleClick}
                        blackBG={props.isDark}
                        priority={true}
                        loading='eager'
                        key={props.src}
                    />
                </div>

            </div>
        </motion.div>

    );
};

